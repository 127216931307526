import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-276b10c2")
const _hoisted_1 = { class: "grid-item grid-item--footer" }
const _hoisted_2 = { class: "inner" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeFooterToolsDesktop = _resolveComponent("HomeFooterToolsDesktop")!
  const _component_HomeFooterToolsMobile = _resolveComponent("HomeFooterToolsMobile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (['lg', 'xl', 'xxl'].includes(_ctx.mq.current) && _ctx.isAssetsView)
        ? (_openBlock(), _createBlock(_component_HomeFooterToolsDesktop, { key: 0 }))
        : _createCommentVNode("", true),
      (['xs', 'sm', 'md'].includes(_ctx.mq.current))
        ? (_openBlock(), _createBlock(_component_HomeFooterToolsMobile, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ]))
}