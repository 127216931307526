
import { Options, Vue } from "vue-class-component";
import { FoldersWithAssetsInterface } from "@/types";
import Tree from "./Tree/index.vue";
import { PropType } from "vue";

@Options({
  name: "AlbumList",
  components: { Tree },
  props: {
    filteredAlbums: {
      type: Array as PropType<FoldersWithAssetsInterface[]>,
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.params && newVal.params.albumPath) {
          this.pathIds = [...newVal.params.albumPath];
        } else {
          this.pathIds = [];
        }
      },
    },
  },
})
export default class AlbumList extends Vue {
  public pathIds: Array<string> = [];
  public activeAlbum: FoldersWithAssetsInterface | null = null;

  protected handleAlbumClick(node: FoldersWithAssetsInterface): void {
    if (this.pathIds.includes(node.slug)) {
      this.activeAlbum = null;
      this.pathIds = this.pathIds.filter((slug: string) => slug !== node.slug);
    } else {
      this.activeAlbum = node;
      this.pathIds.push(node.slug);
    }
  }
}
