import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-74f1f8d5")
const _hoisted_1 = { class: "albums-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "loading-icon-holder"
}
const _hoisted_3 = { key: 1 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_AlbumList = _resolveComponent("AlbumList")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_perfect_scrollbar, { options: { wheelPropagation: false } }, {
      default: _withCtx(() => [
        (_ctx.dataLoadingInProgress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SvgIcon, {
                local: "",
                src: require('./images/double-ring-laoder.svg')
              }, null, 8, ["src"])
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!_ctx.dataLoadingInProgress && _ctx.filteredAlbums.length)
                ? (_openBlock(), _createBlock(_component_AlbumList, {
                    key: 0,
                    "filtered-albums": _ctx.filteredAlbums
                  }, null, 8, ["filtered-albums"]))
                : (!_ctx.dataLoadingInProgress && !_ctx.filteredAlbums.length)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, " No assets found "))
                  : _createCommentVNode("", true)
            ], 64))
      ]),
      _: 1
    })
  ]))
}