import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import {
  AssetsInterface,
  FolderDataInterface,
  responseInterface,
  FoldersWithAssetsInterface,
  PinnedAssetInterface,
  TagsInterface,
  FiltersInterface,
} from "@/types";
import {
  associateAssetsWithAlbums,
  getAlbumsAssociatedWithAssets,
  filterAssets,
  generateAlbumTree,
} from "@/utils/helpers";
import { languagesModule, usersModule } from "@/store";
import api from "@/apiClient";

@Module
export default class AssetsDataModule extends VuexModule {
  private assets: AssetsInterface[] = [];
  private albums: FolderDataInterface[] = [];
  private albumsWithoutAssets: FolderDataInterface[] = [];
  private filteredAlbums: FoldersWithAssetsInterface[] = [];
  private pinnedAssets: AssetsInterface[] = [];
  private tags: TagsInterface[] = [];
  private globalLoader = false;
  private allDataLoader = false;
  private assetsLoader = false;
  private pinnedAssetsLoader = true;
  private filters: FiltersInterface = {
    assetTypes: [],
    searchQuery: "",
    tags: [],
    categories: [],
  };

  @Action
  public async Load(): Promise<void> {
    this.setAssetsLoader(true);

    try {
      const response: responseInterface = await api.assets.fetch(
        languagesModule.getActive.code
      );
      if (response.statusCode !== 200) throw Error("Failed to load assets");
      this.setAssetsData(response.data);
    } catch (error) {
      console.error("Error loading assets:", error);
    } finally {
      this.setAssetsLoader(false);
    }
  }

  @Action
  public async LoadAlbums(): Promise<void> {
    this.setGlobalLoader(true);

    try {
      const response: responseInterface = await api.assets.fetchAlbums(
        languagesModule.getActive.code
      );
      if (response.statusCode !== 200) throw Error("Failed to load albums");
      const albumsAssociatedWithAlbums = associateAssetsWithAlbums(
        response.data,
        this.getAssets
      );

      this.setAlbumsAssociatedWithAssetsData(albumsAssociatedWithAlbums);
      this.setAlbumsData(response.data);
    } catch (error) {
      console.error("Error loading albums:", error);
    } finally {
      this.setGlobalLoader(false);
    }
  }

  @Action
  public async LoadTags(): Promise<void> {
    this.setGlobalLoader(true);

    try {
      const response: responseInterface = await api.assets.fetchTags(
        languagesModule.getActive.code
      );
      if (response.statusCode !== 200) throw Error("Failed to load tags");
      this.setTagsData(response.data);
    } catch (error) {
      console.error("Error loading tags:", error);
    } finally {
      this.setGlobalLoader(false);
    }
  }

  @Action
  async LoadAllData(): Promise<void> {
    this.setAllDataLoader(true);

    await this.LoadTags();
    await this.Load();
    await this.LoadAlbums();

    this.setAllDataLoader(false);
  }

  @Action
  public LoadPinnedAssets(): void {
    const storedUser = usersModule.userData;
    if (!storedUser) return;

    this.setPinnedAssetsLoader(true);

    api.assets
      .fetchPinnedAssets(storedUser!.userId as number)
      .then((response: responseInterface) => {
        if (response.statusCode !== 200) return;

        const data: AssetsInterface[] = response.data;

        if (!data || !Array.isArray(data)) {
          throw Error("Data must be a array!");
        }

        this.setPinnedAssetsData(data);
        this.setPinnedAssetsLoader(false);
      });
  }

  @Action
  public PinAsset(assetId: string): void {
    const storedUser = usersModule.userData;

    api.assets
      .pin(storedUser!.userId as number, assetId)
      .then((data: PinnedAssetInterface) => this.LoadPinnedAssets());
  }

  @Action
  public UnpinAsset(assetId: string): void {
    const storedUser = usersModule.userData;
    api.assets
      .unpin(storedUser!.userId as number, assetId)
      .then((data: PinnedAssetInterface) => this.LoadPinnedAssets());
  }

  @Action
  public SortPinnedAssets(ids: { [key: string]: string[] }): void {
    const storedUser = usersModule.userData;
    api.assets.sortPinnedAssets(storedUser!.userId as number, ids);
  }

  @Action
  public FilterAlbumsByCustomFilter(
    filters: FiltersInterface
  ): Promise<FoldersWithAssetsInterface[]> {
    return new Promise((resolve) => {
      const albums = getAlbumsAssociatedWithAssets(
        this.getAlbumsWithoutAssets,
        filterAssets(this.getAssets, filters)
      );
      resolve(generateAlbumTree(albums));
    });
  }

  @Action
  public Filters(filters: FiltersInterface) {
    this.setFilters(filters);
  }

  @Action
  public ClearFilters(filtersToClear?: (keyof FiltersInterface)[]) {
    let updatedFilters: FiltersInterface = { ...this.filters };

    if (filtersToClear) {
      filtersToClear.forEach((filterKey) => {
        if (filterKey in updatedFilters) {
          if (Array.isArray(updatedFilters[filterKey])) {
            (updatedFilters[filterKey] as any[]) = [];
          } else if (typeof updatedFilters[filterKey] === "string") {
            (updatedFilters[filterKey] as string) = "";
          }
        }
      });
    } else {
      updatedFilters = {
        assetTypes: [],
        searchQuery: "",
        tags: [],
        categories: [],
      };
    }

    this.setFilters(updatedFilters);
  }

  @Mutation
  private setFilters(filters: FiltersInterface) {
    this.filters = filters;
  }

  @Mutation
  private setAssetsData(assets: AssetsInterface[]) {
    this.assets = assets;
  }

  @Mutation
  public setFilteredAlbums(albums: FoldersWithAssetsInterface[]) {
    this.filteredAlbums = albums;
  }

  @Mutation
  public setPinnedAssetsData(assets: AssetsInterface[]) {
    this.pinnedAssets = assets;
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  @Mutation
  private setAllDataLoader(loader: boolean) {
    this.allDataLoader = loader;
  }

  @Mutation
  private setAssetsLoader(loader: boolean) {
    this.assetsLoader = loader;
  }

  @Mutation
  private setPinnedAssetsLoader(loader: boolean) {
    this.pinnedAssetsLoader = loader;
  }

  @Mutation
  private setAlbumsData(albums: FolderDataInterface[]) {
    this.albumsWithoutAssets = albums;
  }

  @Mutation
  private setAlbumsAssociatedWithAssetsData(albums: FolderDataInterface[]) {
    this.albums = albums;
  }

  @Mutation
  private setTagsData(tags: TagsInterface[]) {
    this.tags = tags;
  }

  get getAssetsData(): AssetsInterface[] {
    return this.assets.sort((a, b) => a.npk - b.npk);
  }

  get getAssets(): AssetsInterface[] {
    return this.assets;
  }

  get getFoldersWithAssets(): FoldersWithAssetsInterface[] {
    const albums: FolderDataInterface[] = [...this.getAlbums];
    return generateAlbumTree(albums);
  }

  get getAlbums(): FolderDataInterface[] {
    return this.albums;
  }

  get getAlbumsWithoutAssets(): FolderDataInterface[] {
    return this.albumsWithoutAssets;
  }

  get getFilteredAlbums(): FoldersWithAssetsInterface[] {
    return this.filteredAlbums;
  }

  get getTags(): TagsInterface[] {
    return this.tags;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }

  get getAllDataLoader(): boolean {
    return this.allDataLoader;
  }

  get getAssetsLoader(): boolean {
    return this.assetsLoader;
  }

  get getPinnedAssetsLoader(): boolean {
    return this.pinnedAssetsLoader;
  }

  get getPinnedAssets(): AssetsInterface[] {
    return this.pinnedAssets;
  }

  get getFilters(): FiltersInterface {
    return this.filters;
  }

  get hasFilterActive(): boolean {
    const filterValues = Object.values(this.getFilters);

    return filterValues.some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      } else {
        return value.length > 0;
      }
    });
  }
}
